export default [
  {
    key: 'imageId',
    initKey: 'image',
    label: 'field.image',
    type: 'async-single-image',
    rules: 'required',
    pw: 500,
    ph: 281,
    fullWidth: false,
    hideDescription: true,
    cols: 12,
  },
  {
    key: 'nameEn',
    label: 'field.nameEn',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'nameKm',
    label: 'field.nameKm',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'sequenceOrder',
    label: 'field.sequenceOrder',
    rules: 'required|integer|min_value:1',
    type: 'currency',
  },
  {
    hide: true,
    skip: true,
    cols: 12,
  },
  {
    key: 'isEnable',
    label: 'field.active',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
]
